import React from 'react'
import SEO from 'components/SEO/SEO'
import {
  ContentSection,
  ContentTitle,
  FieldSection,
  FieldParagraph,
  FieldTitle,
  LinkToFile,
} from 'components/StyledComponents/StyledComponents'
import { Field2Columns } from '../components/StyledComponents/StyledComponents'

export const reviewers = [
  'Karl Ortner',
  'Judit Katona Kovács',
  'Klaus Wagner',
  'Thomas Dax',
  'Andrzej Kowalski',
  'Dan Marius Voicilas',
  'Walenty Poczta',
  'Andrew F. Fieldsend',
  'Gabriel Popescu',
  'Jonel Subic',
  'Julia Neuwirth',
  'Marek Wigier',
  'Věra Majerová',
  'Nagy Géza',
  'Marcin Wójcik',
  'Dragos Cimpoies',
  'József Antal',
  'Vladimír Székely',
  'Monica Mihaela Tudor',
  'Zbigniew Floriańczyk',
  'Vesna Popovic',
  'Radovan Savov',
  'Adam Wasilewski',
  'Krisztina Melynda Dobay',
  'Wawrzyniec Czubak',
  'Filon Teodoriu',
  'Szabolcs Biro',
  'Zoran Njegovan',
  'Roman Kulikowski',
  'Zorica Vasiljevic',
  'Violeta Florian',
  'Vesna Rodic',
  'Konrad Czapiewski',
  'Drago Cvijanović',
  'Augustyn Woś (†)',
  'Paweł Chmieliński',
  'Jiří Sálus',
  'Barbara Wieliczko',
]

const PeerReviewProcessPage = props => (
  <>
    <SEO title="Peer-review process" />
    <ContentSection>
      <ContentTitle>Peer-review process</ContentTitle>
      <FieldSection>
        <FieldParagraph>
          This journal uses double-blind review, which means that both the
          reviewer and author identities are concealed from the reviewers, and
          vice versa, throughout the review process.
        </FieldParagraph>
        <FieldParagraph>
          To facilitate this, authors need to ensure that their manuscripts are
          prepared in a way that does not give away their identity. To help with
          this preparation please ensure the following when submitting to Rural
          Areas and Development:
        </FieldParagraph>
        <FieldParagraph listItem>
          Submit the Title Page containing the Authors details and Blinded
          Manuscript with no author details as 2 separate files.
        </FieldParagraph>
        <FieldParagraph listItem>
          Title Page should include the title, authors' names and affiliations,
          and a complete address for the corresponding author including
          telephone and e-mail address.
        </FieldParagraph>
        <FieldParagraph>
          Besides the obvious need to remove names and affiliations under the
          title within the manuscript (and this will be done by Editorial Team),
          there are other steps that need to be taken to ensure the manuscript
          is correctly prepared for double-blind peer review. To assist with
          this process please:
        </FieldParagraph>
        <FieldParagraph listItem>
          Use the third person to refer to work the Authors have previously
          undertaken, e.g. replace any phrases like “as we have shown before”...
        </FieldParagraph>
        <FieldParagraph listItem>
          Make sure figures do not contain any affiliation related identifier
        </FieldParagraph>
        <FieldParagraph listItem>
          Do not eliminate essential self-references or other references but
          limit self-references only to papers that are relevant for those
          reviewing the submitted paper.
        </FieldParagraph>
        <FieldParagraph listItem>
          Remove any identifying information, including author names, from file
          names and ensure document properties are also anonymized.
        </FieldParagraph>
      </FieldSection>
      <FieldSection>
        <FieldTitle>List of Reviewers</FieldTitle>
        <Field2Columns listLength={reviewers.length}>
          {reviewers.map(reviewer => (
            <FieldParagraph listItem>{reviewer}</FieldParagraph>
          ))}
        </Field2Columns>
      </FieldSection>
    </ContentSection>
  </>
)

export default PeerReviewProcessPage
